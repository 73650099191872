import { usePageTitlePrefix } from '@core/components/Page/usePageTitlePrefix.hook';
import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';
import { useHubspotChat } from 'modules/Support/useHubspotChat.hook';
import Head from 'next/head';

interface Props {
  browserTabTitle: string;
  allowPageIndexation?: boolean;
}
export const Page: FunctionComponentWithChildren<Props> = ({
  children,
  browserTabTitle,
  allowPageIndexation = false,
}) => {
  const titlePrefix = usePageTitlePrefix();
  useHubspotChat();
  return (
    <>
      <Head>
        <title>{`${titlePrefix}${browserTabTitle} | Muzzo`}</title>
        {!allowPageIndexation ? (
          <meta name="robots" content="noindex,nofollow" />
        ) : null}
      </Head>
      {children}
    </>
  );
};
