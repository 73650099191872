import { useChatContext } from 'modules/Chat/context/ChatContext';
import { useMemo } from 'react';

export const usePageTitlePrefix = () => {
  const { unreadChatMessagesCount } = useChatContext();

  return useMemo(() => {
    if (
      unreadChatMessagesCount === undefined ||
      unreadChatMessagesCount === 0
    ) {
      return '';
    }

    if (unreadChatMessagesCount > 0 && unreadChatMessagesCount <= 9) {
      return `(${unreadChatMessagesCount}) `;
    }

    if (unreadChatMessagesCount > 9) {
      return '(9+) ';
    }
  }, [unreadChatMessagesCount]);
};
