import { Page } from '@core/components/Page/Page.component';
import { MuzzoLogoImage } from '@core/components/SVG/MuzzoLogoImage.component';
import { FunctionComponent } from 'react';

interface Props {
  browserTabTitle?: string;
  loadingMessage?: string;
}

export const LogoLoaderFullPage: FunctionComponent<Props> = ({
  browserTabTitle = 'Redirection',
  loadingMessage = 'Merci de patienter...',
}) => {
  return (
    <Page browserTabTitle={browserTabTitle}>
      <div className="flex min-h-screen items-center justify-center">
        <div className="flex flex-col items-center gap-y-2">
          <MuzzoLogoImage />
          <div className="text-catalina-blue text-sm font-medium ">
            {loadingMessage}
          </div>
        </div>
      </div>
    </Page>
  );
};
