import { backOff } from 'exponential-backoff';
import { reportErrorToSentry } from 'modules/Analytics/utils/reportError.utils';
import { useEffect, useRef } from 'react';
import { Routes } from 'router/routes';

/**
 * This hook has 2 purposes:
 * 1. Control the visibility of the support chat button
 * 2. Make sure that the support chat is correctly loaded if it is visible
 *
 * Regarding visibility, the expected behavior is:
 * Load employer job descriptions page: support chat button is visible, there is no flickering.
 * Then go to messaging page: button disappears.
 * Then reload messaging page: button briefly appears and disappears.
 * Then go to job descriptions page: button reappears.
 * Then copy the URL of a chat channel, disconnect and enter the URL. When arriving on the messaging page, the button should appear briefly and disappear.
 * Finally, from messaging page, go to job descriptions page and back to messaging page very quick: button briefly appears and disappears.
 *
 *
 * The documentation of the chat can be found here https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
 *
 * WARNING: there's some dark magic down there, if you modify the code below, make sure to try everything works
 * by reloading website multiple time
 */
export const useHubspotChat = () => {
  const hubspotRef = useRef<HTMLElement | undefined>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    findHubspotRef().then((ref) => (hubspotRef.current = ref));
    return () => (hubspotRef.current = undefined);
  }, []);

  useEffect(() => {
    const hideOrShowHubspotElement = (href: string) => {
      if (hubspotRef.current) {
        if (
          href.includes(Routes.EMPLOYER_MESSAGING_CHANNEL) ||
          href.includes(Routes.RECRUITER_MESSAGING_CHANNEL) ||
          href.includes(Routes.REDIRECTION_AFTER_LOGIN) ||
          href.includes(Routes.REDIRECTION_TO_SIGNUP)
        ) {
          hubspotRef.current.style.visibility = 'hidden';
        } else {
          hubspotRef.current.style.visibility = 'visible';
        }
      }
    };
    // First try to hide or display the chat direclty on page load
    hideOrShowHubspotElement(location.href);

    // But there is some randomness in the chat, so here's a hacky way
    // to try have the correct behavior
    const timeout = setInterval(() => {}, 5_000);

    return () => {
      clearInterval(timeout);
    };
  });
};

const RETRY_PARAMS = {
  numOfAttempts: 30,
  startingDelay: 200,
  maxDelay: 20_000,
};

async function findHubspotRef() {
  try {
    const hubspotElement = await backOff<HTMLElement>(
      () =>
        new Promise((resolve, reject) => {
          const element = document.querySelector<HTMLElement>(
            '[id=hubspot-messages-iframe-container]'
          );
          if (!element) {
            reject(
              new Error('Could not find the support chat div in the document')
            );
          } else {
            resolve(element);
          }
        }),
      RETRY_PARAMS
    );

    return hubspotElement;
  } catch (e) {
    reportErrorToSentry(e);
  }
}
