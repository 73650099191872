import { memo } from 'react';

interface Props {
  height?: number;
  width?: number;
  isWhite?: boolean;
}

const MuzzoLogoImageComponent = ({
  height = 20,
  width = 120,
  isWhite = false,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 142 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6276 0.00756811H19.635C30.0838 0.00337255 39.199 -0.54741 39.199 8.61639C39.199 11.5302 39.2169 15.0526 39.2331 17.6571L39.2579 17.6697V21.2215C39.2584 21.3133 39.2588 21.3665 39.2588 21.3665H39.2579V23.2449C35.3624 23.3463 33.8183 21.1949 33.5215 20.0626C33.3713 19.4857 33.3334 18.899 33.4303 18.3086C33.5208 17.7522 33.7228 17.2322 33.9722 16.7292C34.1433 16.3852 34.3293 16.0474 34.5023 15.7046C34.5096 15.6896 34.5174 15.6752 34.5249 15.6603C34.5834 15.543 34.6404 15.4251 34.6944 15.3057C35.3448 13.8716 35.5786 12.3798 35.2241 10.8305C34.878 9.31822 34.0358 8.15115 32.6647 7.37707C31.6921 6.82652 30.6264 6.60556 29.5195 6.56267C29.3477 6.55591 29.1756 6.55428 29.0042 6.55544C24.0867 6.41885 22.5156 10.0566 22.2882 10.5352C19.2896 16.8427 26.0134 20.4071 26.0134 20.4071L19.6324 26L19.6276 25.9958H19.6314L19.6269 26L13.2456 20.4071C13.2456 20.4071 19.9694 16.8427 16.9709 10.5352C16.7434 10.0566 15.1724 6.41885 10.2548 6.55544C10.0835 6.55428 9.91141 6.55591 9.73957 6.56267C8.63265 6.60556 7.56697 6.82652 6.59441 7.37707C5.22348 8.15115 4.38103 9.31822 4.03523 10.8305C3.68048 12.3798 3.91431 13.8716 4.56465 15.3057C4.61863 15.4251 4.67567 15.543 4.73413 15.6603C4.74168 15.6752 4.74945 15.6896 4.75676 15.7046C4.92978 16.0474 5.11599 16.3852 5.28689 16.7292C5.53628 17.2322 5.73829 17.7522 5.8288 18.3086C5.92568 18.899 5.88773 19.4857 5.73758 20.0626C5.44081 21.1949 3.89663 23.3463 0.00118215 23.2449V21.3665H0C0 21.3665 0.000474773 21.3133 0.00118215 21.2215V17.6697L0.0259309 17.6571C0.0421942 15.0526 0.0598723 11.5302 0.0598723 8.61639C0.0598723 -0.54741 9.17527 0.00337255 19.6241 0.00756811H19.6314H19.6276Z"
      fill={isWhite ? '#FFFFFF' : '#0B39AF'}
    />
    <path
      d="M71.775 20.5849V10.6705C71.775 8.83351 71.315 7.44153 70.3951 6.49461C69.4751 5.54768 68.2006 5.07422 66.5715 5.07422C65.5174 5.07422 64.5783 5.28254 63.7542 5.69919C62.9301 6.0969 62.1826 6.69346 61.5118 7.48888C61.1093 6.7124 60.5344 6.11584 59.7869 5.69919C59.0586 5.28254 58.1866 5.07422 57.1708 5.07422C56.615 5.07422 56.1071 5.1405 55.6471 5.27307C55.2063 5.40564 54.8038 5.58556 54.4397 5.81282C54.0756 6.04008 53.7402 6.30522 53.4335 6.60824C53.146 6.89232 52.8777 7.19533 52.6285 7.51729V5.3583H48.2588V20.5849H52.6285V12.0909C52.6285 11.0683 52.8585 10.3012 53.3185 9.78991C53.7976 9.25963 54.4493 8.99449 55.2734 8.99449C56.0975 8.99449 56.73 9.25963 57.1708 9.78991C57.6116 10.3012 57.832 11.0683 57.832 12.0909V20.5849H62.2018V12.0909C62.2018 11.0683 62.4318 10.3012 62.8917 9.78991C63.3709 9.25963 64.0225 8.99449 64.8466 8.99449C65.6707 8.99449 66.3032 9.25963 66.744 9.78991C67.1848 10.3012 67.4052 11.0683 67.4052 12.0909V20.5849H71.775Z"
      fill={isWhite ? '#FFFFFF' : '#01164D'}
    />
    <path
      d="M89.5759 20.5849V5.3583H85.2061V13.8522C85.2061 14.8749 84.957 15.6514 84.4587 16.1817C83.9795 16.693 83.3183 16.9487 82.475 16.9487C81.6317 16.9487 80.9801 16.693 80.5201 16.1817C80.0601 15.6514 79.8302 14.8749 79.8302 13.8522V5.3583H75.4604V15.2158C75.4604 16.9771 75.9108 18.3596 76.8116 19.3633C77.7315 20.3671 79.0252 20.8689 80.6926 20.8689C81.2484 20.8689 81.7467 20.8027 82.1875 20.6701C82.6283 20.5375 83.0308 20.3576 83.395 20.1303C83.7591 19.9031 84.0849 19.6474 84.3724 19.3633C84.6791 19.0603 84.957 18.7478 85.2061 18.4259V20.5849H89.5759Z"
      fill={isWhite ? '#FFFFFF' : '#01164D'}
    />
    <path
      d="M106.366 20.5849V17.0907H98.3742L106.366 8.39793V5.3583H93.2283V8.85245H100.933L92.9695 17.5452V20.5849H106.366Z"
      fill={isWhite ? '#FFFFFF' : '#01164D'}
    />
    <path
      d="M122.565 20.5849V17.0907H114.573L122.565 8.39793V5.3583H109.427V8.85245H117.132L109.169 17.5452V20.5849H122.565Z"
      fill={isWhite ? '#FFFFFF' : '#01164D'}
    />
    <path
      d="M141.812 13V12.9432C141.812 11.8447 141.601 10.8221 141.179 9.87513C140.758 8.90927 140.173 8.07597 139.426 7.37525C138.697 6.65558 137.825 6.0969 136.81 5.69919C135.794 5.28254 134.692 5.07422 133.503 5.07422C132.296 5.07422 131.184 5.28254 130.169 5.69919C129.153 6.11584 128.271 6.68399 127.524 7.40366C126.776 8.12332 126.192 8.96608 125.77 9.93195C125.348 10.8789 125.138 11.9016 125.138 13V13.0568C125.138 14.1552 125.348 15.1874 125.77 16.1532C126.192 17.1002 126.767 17.9335 127.495 18.6531C128.242 19.3539 129.124 19.9125 130.14 20.3292C131.156 20.7269 132.258 20.9258 133.446 20.9258C134.653 20.9258 135.765 20.7174 136.781 20.3008C137.797 19.8841 138.678 19.316 139.426 18.5963C140.173 17.8767 140.758 17.0434 141.179 16.0964C141.601 15.1306 141.812 14.0984 141.812 13ZM137.499 13.0568C137.499 13.625 137.404 14.1647 137.212 14.676C137.039 15.1684 136.781 15.604 136.436 15.9828C136.091 16.3616 135.669 16.6646 135.171 16.8919C134.692 17.1002 134.136 17.2043 133.503 17.2043C132.89 17.2043 132.334 17.0907 131.836 16.8634C131.357 16.6362 130.935 16.3332 130.571 15.9544C130.207 15.5567 129.929 15.1116 129.737 14.6192C129.546 14.1079 129.45 13.5681 129.45 13V12.9432C129.45 12.375 129.536 11.8447 129.709 11.3523C129.9 10.841 130.169 10.3959 130.514 10.0172C130.859 9.6384 131.271 9.34485 131.75 9.13653C132.248 8.90927 132.813 8.79564 133.446 8.79564C134.04 8.79564 134.586 8.90927 135.085 9.13653C135.583 9.36379 136.014 9.67628 136.378 10.074C136.742 10.4528 137.02 10.8978 137.212 11.4092C137.404 11.9016 137.499 12.4318 137.499 13V13.0568Z"
      fill={isWhite ? '#FFFFFF' : '#01164D'}
    />
  </svg>
);

export const MuzzoLogoImage = memo(MuzzoLogoImageComponent);
